import React, { useEffect, forwardRef, useCallback } from 'react';
import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";
import ReactDOM from 'react-dom';
import { Provider, connect } from 'react-redux'
import { ConnectedRouter } from 'connected-react-router'
import CssBaseline from '@material-ui/core/CssBaseline';
import { ThemeProvider } from '@material-ui/styles';
import { SnackbarProvider, SnackbarContent, useSnackbar } from 'notistack';
import { useLocation } from "react-router-dom";
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import TagManager from 'react-gtm-module';
import ReactGA from "react-ga4";
import clsx from 'clsx';
import { fetchUserLocation, setUserLocation, setCenterMarkerCoords } from './data/actions/parkingSpot';

import store, { history } from './data/store';
import theme from './theme';
import routes from './routes';
import Loader from './components/common/loader';
import Fallback from './components/common/fallback';

import './i18n';
import { fetchHostProfile, fetchGuestProfile } from './data/actions/user';
import { addLoader, changeI18nLanguage } from './data/actions/config';
import Notifier from './components/common/notifier';
import { Box, Typography } from '@material-ui/core';

import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';
import RefreshIcon from '@material-ui/icons/Refresh';
import { makeStyles } from '@material-ui/core/styles';
import { ReactComponent as CancelIcon } from '../src/assets/images/cancel-snackbar.svg';
import { IntercomProvider, useIntercom } from 'react-use-intercom';
import { useTranslation } from 'react-i18next';
import Cookies from 'js-cookie';

Sentry.init({
  dsn: process.env.REACT_APP_STAGE === "prod"
    ? "https://ed7e2c99f0ac497da0ee61007da6367b@o1237430.ingest.sentry.io/6401299"
    : "https://d0035f8a2c444e8eb1ef36282c2adec7@o1237430.ingest.sentry.io/6398799",
  integrations: [new BrowserTracing()],
  tracesSampleRate: process.env.REACT_APP_STAGE === "prod" ? 0.2 : 1.0,
});


const currentAppVersion = "1";
const INTERCOM_APP_ID = 'js400j02';

const ScrollToTop = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
}

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

const getGtmId = () => {
  if (process.env.REACT_APP_STAGE === "prod") {
    return 'GTM-KWXC46B';
  } else if (process.env.REACT_APP_STAGE === "stage") {
    return 'GTM-563S6H3';
  } else {
    return 'GTM-WXRNGVZ';
  }
}

const getGaMeasurementId = () => {
  if (process.env.REACT_APP_STAGE === "prod") {
    return 'G-XG3Y33QH8R';
  } else if (process.env.REACT_APP_STAGE === "stage") {
    return 'G-CWVQRL8E3M';
  }
}

const mapStateToProps = (state) => {
  return {
    token: state.auth.token,
    activeLanguage: state.config.activeLanguage,
    languages: state.config.languages,
    email: state.user.email,
    user: state.user,
    userType: state.user.userType,
    fullName: state.user.fullName,
    appVersion: state.config.appVersion,
  };
};

const handleForceRefresh = () => {
  navigator.serviceWorker
    .getRegistrations()
    .then((registrations) =>
      Promise.all(registrations.map((r) => r.unregister())),
    )
    .then(() => {
      window.location.reload()
    })
}

const tagManagerArgs = {
  gtmId: getGtmId()
};

TagManager.initialize(tagManagerArgs);
ReactGA.initialize([{
    trackingId: getGaMeasurementId()
  }]);


const App = connect(mapStateToProps, { fetchHostProfile, fetchGuestProfile, addLoader, fetchUserLocation, setUserLocation, setCenterMarkerCoords, changeI18nLanguage })(
  ({
    token,
    email,
    fullName,
    userType,
    fetchHostProfile,
    fetchGuestProfile,
    addLoader,
    fetchUserLocation,
    setUserLocation,
    appVersion,
    languages,
    user,
    activeLanguage,
    changeI18nLanguage,
  }) => {
    const query = useQuery();
    const { i18n } = useTranslation();
    const { hardShutdown } = useIntercom();

    useEffect(() => {
      if (token) {
        if (userType === "Guest") {
          fetchGuestProfile(token);
        } else if (userType === "Host") {
          addLoader();
          fetchHostProfile(token);
        }
      }
    }, [token, userType, fetchHostProfile, fetchGuestProfile]);

    const utmSource = query.get('utm_source');
    const prevUtmCookie = Cookies.get('utm_source')
    if (!!utmSource && !!prevUtmCookie) {
      Cookies.remove('utm_source')
      Cookies.set('utm_source', utmSource, { expires: 30 })
    }

    if (!!utmSource && !prevUtmCookie) {
      Cookies.set('utm_source', utmSource, { expires: 30 })
    }

    useEffect(() => {
      const storedLanguage = localStorage.getItem('lang');
      const language = query.get('lang')?.toLowerCase();
      if(language && languages.filter(lang => lang.code === language).length) {
        changeI18nLanguage(i18n, language);
      } else if (token != null && user.locale && activeLanguage !== user.locale){
        changeI18nLanguage(i18n, user.locale);
      } else if (storedLanguage && storedLanguage !== activeLanguage && token == null) {
        changeI18nLanguage(i18n, storedLanguage);
      }
    }, [user.locale, activeLanguage]);

    useEffect(() => {
      navigator.geolocation.getCurrentPosition(function(position) {
        setUserLocation({latitude: position.coords.latitude, longitude: position.coords.longitude});
        setCenterMarkerCoords({lat: position.coords.latitude, lng: position.coords.longitude});
      },
      function(error) {
        if (error.code == error.PERMISSION_DENIED)
        fetchUserLocation();
      });
    }, [])

    useEffect(() => {
      hardShutdown()
    }, [])

    const theme = useTheme();
    let isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const {pathname} = useLocation();
    let url = null;

    if (document.location.origin + pathname + query === document.location.origin + pathname){
      url = document.location.origin + pathname;
    }
    else {
      url = document.location.origin + pathname + "?" + query
    }
    
    useEffect(() => {
      // Intercom update for routes
      window.Intercom("update", {"hide_default_launcher": true});
      window.dataLayer.push({
        event: 'pageview',
        eventProps: {
          category: "page",
          action: "pageview",
          label: "page view event",
          value: "1"
        },
        page: {
          url: url,
          title: document.title,
        }
      });

      ReactGA.send({ hitType: "pageview", page: url });

    }, [pathname])

    return (
      <React.Fragment>
        <ScrollToTop />
        <Loader />
        <Notifier />
        {
          (Number(currentAppVersion) < Number(appVersion)) &&
          (
            <Box onClick={() => handleForceRefresh()} display="flex" position="fixed" right="1rem" bottom="1rem" zIndex="9999" style={{ backgroundColor: "#f5f5f5", border: "1px solid #ccc", borderRadius: "2rem", padding: "0.5rem 0.5rem", cursor: "pointer" }}>
              <RefreshIcon color="primary" style={isMobile ? {fontSize: "1.875rem"} : {fontSize: "1.5rem"}} />
              <Box display="flex" flexDirection="column" justifyContent="center">
                <Typography variant="body2">&nbsp;Get Latest Version&nbsp;</Typography>
              </Box>
            </Box>
          )
        }
        { routes }
      </React.Fragment>
    );
  }
);


const useStyles = makeStyles({
  root: {
    width: "100%",
    padding: theme.spacing(2),
    [theme.breakpoints.up("md")]: {
      maxWidth: "20rem"
    },
  },
  error: {
    color: "white",
    backgroundColor: "#f54646 !important",
    borderRadius: "0.875rem !important"
  },
  success: {
    color: "white",
    backgroundColor: `${theme.palette.primary.main} !important`,
    borderRadius: "0.875rem !important"
  },
  warning: {
    color: "white",
    backgroundColor: "#f54646 !important",
    borderRadius: "0.875rem !important"
  },
  info: {
    color: "black",
    backgroundColor: `${theme.palette.secondary.main} !important`,
    borderRadius: "0.875rem !important"
  },
});

const titleFromVariant = (variant, t) => {
  switch (variant) {
    case "error":
      return t("common.texts.warning");
    case "success":
      return t("common.texts.success");
    case "warning":
      return t("common.texts.warning");
    case "info":
      return t("common.texts.message");
    default:
      return t("common.texts.notification");
  }
};

const SnackMessage = forwardRef((props, ref) => {
  const classes = useStyles();
  const { closeSnackbar } = useSnackbar();
  const { t } = useTranslation();

  const handleDismiss = useCallback(() => {
      closeSnackbar(props.id);
  }, [props.id, closeSnackbar]);

  useEffect(() => {
    const alerts = document.getElementsByName('snack')
    if (alerts.length > 0) {
      alerts[0].parentElement.parentElement.parentElement.parentElement.parentElement.style.zIndex = 99999
    }
  }, [])



  return props.message ? (
    <SnackbarContent ref={ref} name="snack" className={clsx(classes.root, classes[props.message.variant])}>
      <Box display="flex" flexDirection="column" width="100%" color="inherit">
        <Box display="flex" justifyContent="space-between" width="100%" color="inherit">
          <Typography variant="subtitle2" color="inherit">{titleFromVariant(props.message.variant, t)}</Typography>
          <CancelIcon color="inherit" onClick={handleDismiss} fontSize="small" />
        </Box>
        <Box mt={1} width="100%" color="inherit">
          <Typography variant="body2" color="inherit">{props.message.text}</Typography>
        </Box>
      </Box>
    </SnackbarContent>
  ) : null;
});

const appContainer = (
  <Sentry.ErrorBoundary fallback={<Fallback />}>
    <Provider store={store}>
      <React.StrictMode>
        <ThemeProvider theme={theme}>
          <SnackbarProvider
            maxSnack={6}
            anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
            hideIconVariant
            content={(key, message) => <SnackMessage id={key} message={message} />}
          >
              <CssBaseline />
              <ConnectedRouter history={history}>
                <IntercomProvider appId={INTERCOM_APP_ID}>
                  <App />
                </IntercomProvider>
              </ConnectedRouter>
          </SnackbarProvider>
        </ThemeProvider>
      </React.StrictMode>
    </Provider>
  </Sentry.ErrorBoundary>
);

const appDOMNode = document.getElementById('root');

ReactDOM.render(appContainer, appDOMNode);

serviceWorkerRegistration.register();
